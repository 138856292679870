body .MuiPaper-elevation1 {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);
}

body {
  background: url("./images/userBackgrounds/service-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

ul {
  list-style: none;
}

.form-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}
.btnpagination {
  margin: 5px 5px;
  padding: 2px 0;
  background-color: #d8e9f3;
  min-width: 26px;
  border: none;
  border-radius: 5px;
}
.btnactive {
  margin: 5px 5px;
  background-color: #1ff7da;
  padding: 2px 0;
  min-width: 26px;
  border: none;
  border-radius: 5px;
}

#prev {
  color: grey;
}
#next {
  color: grey;
}

.horizontal-center {
  margin: 0;
  position: absolute;
  /* top: 50%; */
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.MuiBackdrop-root {
  background-color: #00000000 !important;
}
.MuiPaper-elevation24 {
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important ;
}

.receipt-content .invoice-wrapper .payment-details span {
  color: #a9b0bb;
  display: block;
}
.receipt-content .invoice-wrapper .payment-details a {
  display: inline-block;
  margin-top: 5px;
}

.receipt-content .invoice-wrapper .line-items .print a {
  display: inline-block;
  border: 1px solid #9cb5d6;
  padding: 13px 13px;
  border-radius: 5px;
  color: #708dc0;
  font-size: 13px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.receipt-content .invoice-wrapper .line-items .print a:hover {
  text-decoration: none;
  border-color: #333;
  color: #333;
}

.receipt-content {
  background: #eceef4;
}
@media (min-width: 1200px) {
  .receipt-content .container {
    width: 900px;
  }
}

.receipt-content .logo {
  text-align: center;
  margin-top: 50px;
}

.receipt-content .logo a {
  font-family: Myriad Pro, Lato, Helvetica Neue, Arial;
  font-size: 36px;
  letter-spacing: 0.1px;
  color: #555;
  font-weight: 300;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.receipt-content .invoice-wrapper .intro {
  line-height: 25px;
  color: #444;
}

.receipt-content .invoice-wrapper .payment-info {
  margin-top: 25px;
  padding-top: 15px;
}

.receipt-content .invoice-wrapper .payment-info span {
  color: #a9b0bb;
}

.receipt-content .invoice-wrapper .payment-info strong {
  display: block;
  color: #444;
  margin-top: 3px;
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .payment-info .text-right {
    text-align: left;
    margin-top: 20px;
  }
}
.receipt-content .invoice-wrapper .payment-details {
  border-top: 2px solid #ebecee;
  margin-top: 30px;
  padding-top: 20px;
  line-height: 22px;
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .payment-details .text-right {
    text-align: left;
    margin-top: 20px;
  }
}
.receipt-content .invoice-wrapper .line-items {
  margin-top: 40px;
}
.receipt-content .invoice-wrapper .line-items .headers {
  color: #a9b0bb;
  font-size: 13px;
  letter-spacing: 0.3px;
  border-bottom: 2px solid #ebecee;
  padding-bottom: 4px;
}
.receipt-content .invoice-wrapper .line-items .items {
  margin-top: 8px;
  border-bottom: 2px solid #ebecee;
  padding-bottom: 8px;
}
.receipt-content .invoice-wrapper .line-items .items .item {
  padding: 10px 0;
  color: #696969;
  font-size: 15px;
}
@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .items .item {
    font-size: 13px;
  }
}
.receipt-content .invoice-wrapper .line-items .items .item .amount {
  letter-spacing: 0.1px;
  color: #84868a;
  font-size: 16px;
}
@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .items .item .amount {
    font-size: 13px;
  }
}

.receipt-content .invoice-wrapper .line-items .total {
  margin-top: 30px;
}

.receipt-content .invoice-wrapper .line-items .total .extra-notes {
  float: left;
  width: 40%;
  text-align: left;
  font-size: 13px;
  color: #7a7a7a;
  line-height: 20px;
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .total .extra-notes {
    width: 100%;
    margin-bottom: 30px;
    float: none;
  }
}

.receipt-content .invoice-wrapper .line-items .total .extra-notes strong {
  display: block;
  margin-bottom: 5px;
  color: #454545;
}

.receipt-content .invoice-wrapper .line-items .total .field {
  margin-bottom: 7px;
  font-size: 14px;
  color: #555;
}

.receipt-content .invoice-wrapper .line-items .total .field.grand-total {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}

.receipt-content .invoice-wrapper .line-items .total .field.grand-total span {
  color: #20a720;
  font-size: 16px;
}

.receipt-content .invoice-wrapper .line-items .total .field span {
  display: inline-block;
  margin-left: 20px;
  min-width: 85px;
  color: #84868a;
  font-size: 15px;
}

.receipt-content .invoice-wrapper .line-items .print {
  margin-top: 50px;
  text-align: center;
}

.receipt-content .invoice-wrapper .line-items .print a i {
  margin-right: 3px;
  font-size: 14px;
}

.receipt-content .footer {
  margin-top: 40px;
  margin-bottom: 110px;
  text-align: center;
  font-size: 12px;
  color: #969cad;
}

.file_input {
  color: transparent;
  width: 50% !important;
}

.file_input::-webkit-file-upload-button {
  visibility: hidden;
}

.file_input::before {
  content: "UPLOAD IMAGE" !important;
  color: white;
  display: inline-block;
  background-color: #3f51b5;
  /* background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3); */
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 3px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 400;
  font-size: 10pt;
}
.file_input:hover::before {
  border-color: #3f51b5;
}
.file_input:active {
  outline: 0;
}
.file_input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

@media (max-width: 1278px) {
  .file_input {
    width: 20% !important;
  }
}
@media (max-width: 1278px) {
  .file_input {
    width: 30% !important;
  }
}

@media (min-width: 320px) and (max-width: 474px) {
  .file_input {
    width: 60% !important;
  }
}

@media (min-width: 900px) {
  .repayment_header div{
    margin: 0 3%;
  }
}

.message-list ul:last-child .MuiDivider-root {
  height: 0;
}

.hover .btn_more {
  top: 8px;
  cursor: pointer;
  position: relative;
}

/* .hover {
  position: relative;
  display: block;
} */

/* .hover:hover .btn_more {
  visibility: visible;
  position: relative;
  top: 10px;
  cursor: pointer;
} */

.caret {
  display: none;
  position: absolute;
  top: 50px;
  right: -10px;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
}


.selected_caret{
  display: block;
  position: absolute;
  top: 50px;
  right: -10px;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
}

.caret::after {
  top: -31px;
  border: 11px solid transparent;
  border-bottom: 20px solid #fff;
}

.selected_caret:after, .caret::after {
  content: "";
  position: absolute;
  right: 10px;
}

.selected_caret:after {
  top: -18px;
  border: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.selected_caret:before {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid #d1d0d0;
  top: -19px;
  right: 10px;
}

.selected_caret ul, .caret ul {
  /* display: block;
  position: absolute; */
  border: 1px solid #d1d0d0;
  list-style: none;
  background: #fff;
  z-index: 1000;
  /* top: 40px;
  right: 8px; */
  margin-bottom: 0rem;
  border-radius: 10px;
}

.selected_caret li, .caret li {
  text-align: left;
  font-size: small;
  padding: 10px 28px;
}

.selected_caret li:hover {
  /* background-color: #e0dddd; */
}

.selected_caret li:not(:last-child), .caret li:not(:last-child) {
  border-bottom: 1px solid #d1d0d0;
}

.id_card {
  color: transparent;
  width: 50% !important;
}

.id_card::-webkit-file-upload-button {
  visibility: hidden;
}

.id_card::before {
  content: "UPLOAD ID CARD" !important;
  color: white;
  display: inline-block;
  background-color: #3f51b5;
  /* background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3); */
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 3px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 400;
  font-size: 10pt;
}
.utility_bill::before {
  content: "UPLOAD UTILITY BILL" !important;
  color: white;
  display: inline-block;
  background-color: #3f51b5;
  /* background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3); */
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 3px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 400;
  font-size: 10pt;
}
.id_card:hover::before {
  border-color: #3f51b5;
}
.id_card:active {
  outline: 0;
}
.id_card:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

@media (max-width: 1278px) {
  .id_card {
    width: 20% !important;
  }
}
@media (max-width: 1278px) {
  .id_card {
    width: 30% !important;
  }
}

@media (min-width: 320px) and (max-width: 474px) {
  .id_card {
    width: 60% !important;
  }
}

@media (max-width: 780px) {
  .makeStyles-searchFilter-19 div input::placeholder {
    font-size: 10px;
  }
}

@media(max-width: 1024px) {
  .makeStyles-searchFilter-19 div input::placeholder {
    font-size: 15px;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkspan{
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 0 solid rgb(78, 77, 77);
}

.container input:checked ~ .checkspan{
  background-color: #2196F3;
}

.checkspan::after{
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkspan::after {
  display: block;
}

.container .checkspan::after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tr_link{
  cursor: pointer;
}

.tr_link:nth-of-type(odd):hover {
  background-color: rgb(228, 222, 222)
}

.tr_link:nth-of-type(even):hover {
  background-color: rgb(250, 248, 248)
}

.item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}

.item p, .item i {
  font-size: large;
  font-weight: 500;
  margin: 0;
}

.savings_table tr:last-child td{
  border-top: 0!important
}

.row_select  .target{
  display: none;
}

.row_select:hover .target{
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
}

.payment_section{
  height: 500px;
  display: block;
}

.payment_items{
  height: 50px;
}

.loan_action ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.loan_action ul li{
  padding: 10px 15px;
  border: 0.5px solid rgb(0, 0, 0);
  font-size: 12px;
  display:flex;
  background-color: white;
  flex-direction: column;
}

.clearfix div{
  overflow-y: auto;
  overflow-x: auto;
}

.add_account_form div:nth-child(2){
  overflow-y: unset;
}

@media screen and (max-width: 490px){
  .info_comp{
    flex-direction: column!important;
  }
  .text_mobile{
    left: 0 !important;
  }
  .img_mobile{
    left: 0 !important;
    width: 100% !important;
  }
}

.rejected_loan{
  float: right;
  display: block;
  position: relative;
  top: -20px;
  background: red;
  color: white;
  border-top: 15px solid red;
	border-left: 15px solid white;
  border-right: 15px solid red;
	border-bottom: 15px solid red;
  padding-left: 13px;
  height: 30px;
  width: 110px;
  right: -20px
}

.rejected_loan div{
  display: flex;
  position: absolute;
  top: -10px;
  left: 2px;
}

@media screen and (max-width: 473px) {
  .rejected_loan{
    margin-bottom: 10px
  }
}

@media screen and (max-width: 960px){
  .acct_btn{
    width: 100%!important;
  }
}

@media screen and (min-width: 1280px){
  .acct_btn{
    width: 100%!important;
  }
}
.acct_btn{
  width: 100%;
  margin-top: 20px;
}

.card_parent{
  /* height: 180px; */
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.loan_section,.acct_btn,.save_section,.bill_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loan_section li h4, .bill_section li h4{
  font-size: 14px;
}

.loan_section li p, .loan_section li h4, .bill_section li h4, .bill_section li p{
  text-align: center;
}

.bill_section li::not(odd){
  width: 100%;
}

.bill_section{
  justify-content: space-evenly!important;
}

.loan_section li::not(even){
  width: 40%;
  border-radius: 5px;
}



.span_btn {
  width: 10%;
  background: rgb(226 218 218 / 14%);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.save_section{
  align-items: center;
}

.btn_loan{
  padding: 5px 0;
  font-weight: 500;
  cursor: pointer;
  width: 70%;
  background: rgb(226 218 218 / 14%);
  text-align: center;
  border-radius: 4px;
}

.btn_loan:hover{
  background: rgb(69, 147, 248);
  color: #fff
}

.save_loan_container{
  width: 100%;
  margin: auto;
}

.acct_holder{
  width: 50%;
  text-align: center;
  margin: auto;
}

.amout_modal, .loan_drop {
  width: 22%;
  position: absolute;
  z-index: 20;
  flex-direction: column;
  background: #fff;
  margin-left: 9.1%;
  margin-top: 41px
}

.amout_modal{
  top: 320px;
}

.loan_drop{
  top: 550px;
}

@media (max-width: 768px) {
  .acct_holder{
    width: 100%;
  }

  .loan_drop{
    width: 82%;
    margin-left: 4.1%;
  }
}

@media (max-width: 425px) {
  .loan_drop{
    margin-left: -3px;
  }
}

@media (max-width: 375px) {
  .loan_drop{
    top: 630px;
  }
}

@media (max-width: 320px) {
  .loan_drop{
    top: 650px;
    margin-left: -5px;
  }
}

.withdraw_form{
  position: absolute;
  display: flex;
  z-index: 33;
  width: 21.5%;
  background: #ffffff!important;
  top: 282px;
}



@media (max-width: 1024px) {
  .withdraw_form{
  width: 30%!important;
  }
}

/* @media (min-width: 2560px) {
  .withdraw_form{
  width: 21.4%;
  }
}*/

@media (max-width: 1440px) {
  .withdraw_form{
  width: 25.4%;
  }
} 

@media (max-width: 768px) {
  .withdraw_form{
    width: 70%!important;
    left: 15%;
  }
}

@media (max-width: 425px) {
  .withdraw_form{
    width: 88%!important;
    left: 22px;
  }
}

.close_widthdraw_form{
  display: none;
}


